<template>
  <div class="text-right bg" :style="{height:heights+'px'}">
    <div class="dflexs  head">
      <img class="returns" @click='returns' src="../assets/level/returns.png" alt="">
      <div class="pagetitle">{{$t('Level.familyLevel')}}</div>
    </div>
    <div>
      <img style="width: 100%;height: 100%;position: relative;" src="../assets/level/bg2.png" alt="">
      <div class="dingwei" style="">
        <div class="huadongg">
          <div class="beijin">
            <div>
              <div style="font-size: 0.2rem;color: #fff; display: flex;justify-content: center;">
                <div class="urlRank" :style="{'backgroundImage':'url('+familyLevelData.userFamilyLevelUrl+')'}">
                  <div style="padding-right: 0.27rem;line-height: 1;">
                    <div style="font-size: 0.2rem;font-family: Regular;margin-top: 0.136rem;">{{$t('Level.familyLevel')}}</div>
                    <div style="font-size: 0.6rem;font-family: Semibold;">Lv{{familyLevelData.userNowFamilyLevel}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" currentExpbox">
              <div class="dagnqian">
                {{$t('Level.currentExp')}}
                {{familyLevelData.userNowFamilyLevelContribute}}，LV.{{familyLevelData.userFamilyLevelRight}}
                {{$t('Level.stillNeed')}}
                {{familyLevelData.nowFamilyLevelContribute-familyLevelData.userNowFamilyLevelContribute&lt;=0?'0':familyLevelData.nowFamilyLevelContribute-familyLevelData.userNowFamilyLevelContribute}}
              </div>
              <div class="progress_barbox">
                <div class="currentExp" :style="{width: (familyLevelData.nowFamilyLevelContribute-familyLevelData.userNowFamilyLevelContribute&lt;=0?'1':familyLevelData.userNowFamilyLevelContribute/familyLevelData.nowFamilyLevelContribute)*100 +'%'}">
                </div>
              </div>
              <div class="dengji">
                <span style="flex: 1;">LV.{{familyLevelData.userFamilyLevelLeft}}</span><span>LV.{{familyLevelData.userFamilyLevelRight}}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="overflow: hidden;" :style="{'maxHeight':slideh+'px'}">
          <div style="overflow: scroll; margin: 0rem 0;" :style="{'maxHeight':slideh+'px'}">
            <span>
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.badgeReward')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div style="margin-bottom: 0.2rem;">
                <van-grid :border="false" :column-num="3">
                  <van-grid-item v-for="(item, index) in badgeReward" :key="index">
                    <!-- <van-image src="../assets/level/1@3x.png" /> -->
                    <img style="height: 1.28rem;" :src="item.lvimg" alt="">
                    <span class="rankvalue">{{item.value}}</span>
                  </van-grid-item>
                </van-grid>
              </div>
            </span>
            <div style="margin-bottom: 0.4rem;">
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.gradePrivileges')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div class="GradePrivileges">
                <div class="biaotou">
                  <div class="titles" style="width: 15%;">{{$t('Level.familyLeveldata[0]')}}</div>
                  <div class="titles" style="width: 25%;">{{$t('Level.familyLeveldata[1]')}}</div>
                  <div class="titles" style="width: 25%;">{{$t('Level.familyLeveldata[2]')}}</div>
                  <div class="titles" style="width: 40%;">{{$t('Level.familyLeveldata[3]')}}</div>
                </div>
                <div class="biaot_content" v-for="(item, index) in familyLevelConfig" :key="index" :style="index==0?'border-top:none':''">
                  <div style="width: 15%;text-align: center;">{{item.level}}</div>
                  <div style="width: 25%;text-align: center;">{{item.contribute}}</div>
                  <div style="width: 25%;text-align: center;">{{item.personCount}}</div>
                  <div class="Position">
                    <div class="flexs Positionbox">
                      <div style="position: relative;">
                        <div class="familyTitle">{{$t('Level.identity[0]')}}</div>
                        <img class="familyTitleimg" src="../assets/level/identity.png" alt="">
                      </div>
                      <span class="familyElder">X{{item.deputyCount}}</span>
                    </div>
                    <div class="flexs" style="padding-top: 0.05rem">
                      <div style="position: relative;">
                        <div class="familyTitle" :class="$i18n.locale=='ar'?'familyTitlear':''">{{$t('Level.identity[1]')}}</div>
                        <img class="familyTitleimg" src="../assets/level/identity.png" alt="">
                      </div>
                      <span class="familyElder">X{{item.elderCount}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-bottom: 0.4rem;">
              <div class="rankExhbox">
                <img style="width: 0.73rem;" :src="leftbr" alt="">
                <span class="reward">{{$t('Level.upgrade')}}</span>
                <img style="width: 0.73rem;" :src="rightbr" alt="">
              </div>
              <div class="help">
                <div v-if="activeIndicator==0" class="flexs upgradebox" v-for="(item, index) in $t('Level.upgradebox.family')" :key="index">
                  <img v-if="index==0" style="width: 0.85rem;" src="../assets/level/Sendgift.png">
                  <img v-if="index==1" style="width: 0.85rem;" src="../assets/level/tasks.png">
                  <div class="contentbox">
                    <div class="titleName">{{item.title}}</div>
                    <div style="color: #DEB888;font-size: 0.2rem;">{{item.value}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script type="text/babel">
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from 'js-cookie';
import leftimg from '../assets/level/left.png';
import rightimg from '../assets/level/right.png';
import leftbr from '../assets/level/leftbr.png';
import rightbr from '../assets/level/rightbr.png';

//财富等级
import fLV1 from '../assets/level/fLV1.png';
import fLV2 from '../assets/level/fLV2.png';
import fLV3 from '../assets/level/fLV3.png';
import fLV4 from '../assets/level/fLV4.png';
import fLV5 from '../assets/level/fLV5.png';
import fLV6 from '../assets/level/fLV6.png';
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      donghua: '',
      danmus: [],
      urlRank: '',
      familyLevelData: [],
      familyLevelConfig: [],
      index: 0,
      disabled: false,
      recordlist: [],
      userInfo: [],
      emptyRecord: false,
      profit: 0,
      token: null,
      uid: null,
      headers: null,
      myiCode: '',
      active: '',
      width: 55,
      imgfx: rightimg,
      leftbr: leftbr,
      rightbr: rightbr,
      heights: 0,
      slideh: 0,
      badgeReward: [
        { lvimg: fLV1, value: 'LV.1' },
        { lvimg: fLV2, value: 'LV.2' },
        { lvimg: fLV3, value: 'LV.3' },
        { lvimg: fLV4, value: 'LV.4' },
        { lvimg: fLV5, value: 'LV.5' },
        { lvimg: fLV6, value: 'LV.6' },
      ],
      activeIndicator: 0,

    };
  },

  created() {
    // this.$completes.loadErudaJs()
    // this.familyLevel()
    // this.invitationRecord()

    $(function () {
      $('.beijin>span').click(function () {
        $(this).css('color', '#DEB888')
        $(this).siblings().css('color', 'rgb(216 186 150 / 30%)')
      })
    })

  },
  mounted() {
    //页面css初始化
    // document.getElementsByTagName("html")[0].style.backgroundColor = "#8260FF";
    document.documentElement.style.overflow = 'hidden';
    document.getElementsByTagName("html")[0].style.padding = "0";
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
    // this.$i18n.locale = 'zh'
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    this.heights = window.screen.height
    var beijin = document.getElementsByClassName('beijin');
    this.slideh = this.heights - beijin[0].offsetHeight - 88
  },


  methods: {
    //返回
    returns() {
      this.$completes.complete("getPreviousPage");
    },

    //原生返回参数
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      const userinfos = Cookies.get('userinfos')
      if (userinfos != undefined) {
        if (userinfos.uid == this.uid) {
          this.myiCode = userinfos.myiCode
        }
      }
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('inviteCash.title')
      this.familyLevel()

    },

    familyLevel() {
      let that = this

      let getUserFamilyLevelInfo = {
        option: 'get',
        host: this.$serviceIp + '/api/code/family/getUserFamilyLevelInfo?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(getUserFamilyLevelInfo).then((data) => {
        if (data.code == 200) {
          that.familyLevelData = data.data
        }
      });

      let getFamilyLevelConfig = {
        option: 'get',
        host: this.$serviceIp + '/api/code/family/getFamilyLevelConfig?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(getFamilyLevelConfig).then((data) => {
        if (data.code == 200) {
          that.familyLevelConfig = data.data
        }
      });
    },
  },

};

</script>

<style>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */

html[lang="ar"] .text-right {
  /* direction: rtl; */
}

html[lang="ar"] .head {
  direction: rtl;
}

html[lang="ar"] .pagetitle {
  margin-left: 0.4rem;
  margin-right: 0;
}

html[lang="ar"] .returns {
  -webkit-transform: rotateY(180deg);
}

html[lang="ar"] .beijin > img {
  -webkit-transform: rotateY(180deg);
}

html[lang="ar"] .currentExpbox {
  direction: rtl;
}

html[lang="ar"] .van-grid {
  direction: rtl;
}

html[lang="ar"] .help {
  direction: rtl;
}

html[lang="ar"] .contentbox {
  text-align: right;
  margin-right: 0.3rem;
}

html[lang="ar"] .GradePrivileges {
  direction: rtl;
}
html[lang="ar"] .Position {
  direction: ltr;
  padding-left: 0;
}

.bg {
  overflow: hidden;
}

.box {
  position: relative;
  margin-top: 88px;
}

.head {
  display: flex;
  align-items: center;
  background: #1d1d1d;
  justify-content: center;
  padding: 0 0.27rem;
  height: 44px;
  margin: 0 !important;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  padding-top: 44px;
}

.returns {
  width: 0.4rem;
  height: 0.4rem;
}

.pagetitle {
  font-size: 0.3rem;
  font-family: Regular;
  color: #ffffff;
  line-height: 0.5rem;
  flex: 1;
  text-align: center;
  margin-right: 0.4rem;
}

.flexs {
  display: flex;
  align-items: center;
}

.dingwei {
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}

.van-tab--active {
  color: #323233;
  font-weight: 600;
}

.urlRank {
  width: 2.84rem;
  height: 0.974rem;
  /* background-image: url('../assets/level/geren45.png'); */
  background-size: 100% 100%;
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
}

.urlRank img {
  width: 0.3rem;
  height: 0.28rem;
  margin-left: 0.1rem;
}

.progress_barbox {
  width: 100%;
  height: 0.136rem;
  background: #ffedd620;
  border-radius: 7px;
  font-size: 0.2rem;
  position: relative;
  overflow: hidden;
}

.currentExpbox {
  height: 1rem;
  font-size: 0.25rem;
  padding: 0.46rem 0.76rem 0;
}

.dagnqian {
  color: #deb78870;
  padding-bottom: 0.06rem;
  font-size: 0.15rem;
  line-height: 1;
  font-family: Regular;
}

.currentExp {
  background: #deb888;
  height: 0.136rem;
  border-radius: 7px;
}

.dengji {
  color: #deb888;
  padding-bottom: 0.1rem;
  display: flex;
  justify-content: center;
  margin-top: 0.17rem;
  font-size: 0.2rem;
  font-family: Regular;
}

.beijin {
  font-size: 0.3rem;
  font-family: Semibold;
  margin-top: 88px;
  padding-top: 0.5rem;
  padding-bottom: 1.55rem;
}

html {
  overflow-x: hidden;
}

.reward {
  color: #deb888;
  font-size: 0.25rem;
  font-family: Regular;
}

.rankExhbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.49rem;
}

.rankExhbox img:nth-child(1) {
  margin-right: 0.4rem;
}

.rankExhbox img:nth-child(3) {
  margin-left: 0.4rem;
}

.van-grid-item__content {
  background: none;
  padding: 0 0 0.5rem;
}

.van-grid {
  justify-content: center;
}

.rankvalue {
  color: #deb888;
  font-size: 0.2rem;
  font-family: Regular;
}

.help {
  text-align: center;
  margin-bottom: 68px;
}

.titleName {
  color: #deb888;
  font-size: 0.23rem;
  margin-bottom: 0.2rem;
}

.upgradebox {
  white-space: nowrap;
  /* justify-content: center; */
  margin: 0 1.17rem;
  margin-bottom: 0.25rem;
}

.contentbox {
  text-align: left;
  font-family: Regular;
  margin-left: 0.3rem;
}

.biaotou {
  width: 100%;
  height: 1.179rem;
  background: #deb88810;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0.25rem 0 0;
}

.biaotou > .titles {
  font-size: 0.2rem;
  font-family: Regular;
  color: #efd8bb;
  width: 3rem;
  padding: 0 0.15rem;
  text-align: center;
}

.biaot_content {
  padding: 0.2rem 0;
  margin: 0 0.15rem;
  border-top: 2px dashed rgba(255, 255, 255, 0.288);
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 0.23rem;
  font-family: Regular;
  font-weight: 400;
  color: #ffffff;
}

.familyTitle {
  font-size: 0.15rem;
  background: #e67dfe;
  padding: 0.03rem 0.1rem 0.03rem 0.3rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: Regular;
  line-height: 1;
}
.familyTitlear {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
}

.familyTitleimg {
  width: 0.4rem;
  height: 0.29rem;
  position: absolute;
  left: -0.15rem;
  top: -0.04rem;
}

.familyElder {
  padding-left: 0.06rem;
  font-size: 0.2rem;
  font-family: Regular;
}

.GradePrivileges {
  margin: 0 0.29rem;
  background: #efd8bb06;
}

.Position {
  padding-left: 0.3rem;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
}

.Positionbox {
  padding-bottom: 0.05rem;
}
</style>